import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { Typography } from "antd";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";
import { ICONS } from "../../config/icons";

import FooterEditor from "../../editors/FooterEditor";

import { RESELLER_HOST } from "../../config";

import style from "../style/footer.module.scss";

const VERSION = require("../../../package.json").version;

const FooterComponent = () => {
  const translation = useTranslation();
  
  const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

  const { isAdmin, widgets, appInfo, webSettings } = useSelector(
    (store) => store.globalState
  );

  const footerStyle = {
    backgroundColor: webSettings?.footer?.background_color,
  };

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };
  return (
    <div className={style["footer"]} style={footerStyle}>
      <div className={style["footer-main"]}>
        {isAdmin && <FooterEditor />}

        <div className={style["footer-navigation_wrapper"]}>
          <NavLink to={PATHS.HOME}>{handleText("home", "page_name")}</NavLink>

          {widgets &&
            widgets.hide_activation_page &&
            widgets.hide_activation_page.enabled &&
            widgets.hide_activation_page.enabled === "false" && (
              <NavLink to={PATHS.ACTIVATION}>
                {handleText("activation", "page_name")}
              </NavLink>
            )}

          {widgets &&
            widgets.hide_upload_playlist &&
            widgets.hide_upload_playlist.enabled &&
            widgets.hide_upload_playlist.enabled === "false" && (
              <NavLink to={PATHS.UPLOAD_PLAYLIST}>
                {handleText("upload_playlist", "page_name")}
              </NavLink>
            )}

          {widgets?.referral_reseller?.enabled === "true" && (
            <a
              href={RESELLER_HOST + "#/login"}
              target="_blank"
              rel="noreferrer"
              className={style["dropdown-item"]}
            >
              {translation?.general?.login || TEXT?.general?.login}
            </a>
          )}

          {widgets?.referral?.enabled === "true" &&
            widgets?.reseller?.enabled === "true" && (
              <NavLink to={PATHS.RESELLER} rel="noreferrer">
                {handleText("register", "page_name")}
              </NavLink>
            )}

          {widgets?.news?.enabled === "true" && (
            <NavLink to={PATHS.NEWS}>{handleText("news", "page_name")}</NavLink>
          )}

          {widgets?.pricing?.enabled === "true" && (
            <NavLink to={PATHS.PRICING}>
              {selectedLanguage.iso_code=="hy"?"Գնացուցակ":
                handleText("pricing", "page_name")}</NavLink>
          )}

          {widgets?.support?.enabled === "true" && (
            <NavLink to={PATHS.CONTACT_US}>
              {handleText("contact_us", "page_name")}
            </NavLink>
          )}

          <NavLink to={PATHS.TERMS_OF_USE}>
          {selectedLanguage.iso_code=="hy" ? "Օգտագործելու կանոններ" :handleText("terms_of_use", "page_name")}
          </NavLink>

          {/* <NavLink to={PATHS.TERMS_OF_SALE}>
            {handleText("terms_of_online_sale", "page_name")}
          </NavLink> */}
          <NavLink to={PATHS.PRIVACY}>
            {selectedLanguage.iso_code=="hy" ? "Գաղտնիության քաղաքականություն" :handleText("privacy", "page_name")}
          </NavLink>
        </div>

        <div className={style["social-links_wrapper"]}>
          {webSettings?.footer?.social_links?.map((item, index) => {
            if (item.enabled) {
              return (
                <a href={item.name === "Email"?`mailto:${item.link}`:item.link} target="_blank" key={item.id}>
                  <img src={item.icon} alt="social link" />
                </a>
              );
            }
          })}
        </div>

        {webSettings?.footer?.payments_cards?.enabled && (
          <div className={style["payments-cards"]}>
            <img src={webSettings?.footer?.payments_cards?.image} />
          </div>
        )}

        <div className={style["copyright-wrapper"]}>
          <div className={style["email"]}>
            {ICONS.EMAIL}

            <a href={`mailto:${appInfo?.email}`} target="_blank">
              <Typography.Text>{appInfo?.email}</Typography.Text>
            </a>
          </div>

          <Typography.Text className={style["reserved"]}>
            {
              webSettings?.footer?.reserved?.enabled ? 
              <a href={webSettings?.footer?.reserved?.link} target="_blanck">
             {webSettings?.footer?.reserved?.name && `${webSettings?.footer?.reserved?.name} ©`}
              </a> :
                <a href="https://easyplayer.io/" target="_blanck">
                  EasyPlayer.io 
                </a>
            }{" "}©
            {new Date().getFullYear()} |{" "}
            {translation?.general?.all_rights_reserved ||
              TEXT?.general?.all_rights_reserved}
          </Typography.Text>
          <div className={style["version"]}>v{VERSION}</div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
