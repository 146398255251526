import { Link } from "react-router-dom";

import { useEffect } from "react";

import { useSelector } from "react-redux";

import PageComponent from "../../components/PageComponent";
import TEXT from "../../config/text";
import "./_referring-pages.scss";
import useTranslation from "../../hooks/useTranslation";

export default function PrivacyPolicyPage() {
    const translation = useTranslation();

    const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

    const handleText = (text) => {
        if (translation?.pages?.privacy?.[text]) {
            return translation?.pages?.privacy?.[text];
        }
        return TEXT?.pages?.privacy?.[text];
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <PageComponent>
            <div className="referring-pages">
                {/* <h1>PRIVACY POLICY</h1> */}
                {
                    selectedLanguage.iso_code == "hy" ? <>
                        <h1>Գաղտնիության քաղաքականություն</h1>
                        <div>I Player-ը ստեղծել է Մեդիա փլեյեր հավելվածը, որպես կոմերցիոն հավելված:

                            Այս ԾԱՌԱՅՈՒԹՅՈՒՆԸ տրամադրվում է inoRain LLC-ի, Inorain - Georgia LLC-ի կողմից և նախատեսված է օգտագործելու համար, ինչպես կա:

                            Այս էջն օգտագործվում է այցելուներին տեղեկացնելու մեր քաղաքականության վերաբերյալ անձնական տեղեկատվության հավաքագրման, օգտագործման և բացահայտման վերաբերյալ, եթե որևէ մեկը որոշել է օգտվել մեր Ծառայությունից:

                            Եթե դուք ընտրում եք օգտվել մեր Ծառայությունից, ապա դուք համաձայնում եք տեղեկատվության հավաքագրմանը և օգտագործմանը այս քաղաքականությանը:

                            Անձնական տեղեկատվությունը, որը մենք հավաքում ենք, օգտագործվում է Ծառայությունը տրամադրելու և բարելավելու համար: Մենք չենք օգտագործի կամ կիսվի ձեր տեղեկությունները որևէ մեկի հետ, բացառությամբ սույն Գաղտնիության քաղաքականության մեջ նկարագրվածի: Սույն Գաղտնիության քաղաքականության մեջ օգտագործվող տերմիններն ունեն նույն նշանակությունը, ինչ մեր Պայմաններում և դրույթներում, որը հասանելի է <Link to="/terms-of-use" >https://i-player.live/#/terms-of-use</Link> կայքում, եթե այլ բան սահմանված չէ սույն Գաղտնիության քաղաքականության մեջ:

                            Տեղեկությունների հավաքագրում և օգտագործում Մեր Ծառայությունից օգտվելիս ավելի լավ փորձի համար մենք կարող ենք պահանջել, որ մեզ տրամադրեք որոշակի անձը հաստատող տեղեկություններ, ներառյալ, MAC հասցեն, էլ. փոստը, անուն, ազգանուն, հասցե, հեռախոսահամար:

                            Տեղեկությունները, որոնք մենք պահանջում ենք, կպահվեն մեր կողմից և կօգտագործվեն այնպես, ինչպես նկարագրված է այս գաղտնիության քաղաքականության մեջ:

                            Հավելվածն օգտագործվում է երրորդ կողմի ծառայությունների կողմից, որոնք կարող են հավաքել տեղեկատվություն, որն օգտագործվում է ձեզ նույնականացնելու համար: Մենք հավաքում ենք տեղեկությունները Log Data-ում, որպեսզի հավելվածում սխալի դեպքում կարողանանք ուղղել դրանք: Աըդ տեղեկությունները կարող են ներառել այնպիսի տեղեկություններ, ինչպիսիք են՝ ձեր սարքի ինտերնետային արձանագրության IP հասցեն, MAC հասցեն, սարքի անունը, օպերացիոն համակարգի տարբերակը,device key-ն, մեր Ծառայությունից օգտվելիս, Ծառայությունից օգտվելու ժամը և ամսաթիվը և այլ վիճակագրություն: Cookie-ները փոքր քանակությամբ տվյալներ ունեցող ֆայլեր են, որոնք սովորաբար օգտագործվում են որպես անանուն եզակի նույնականացնող տվյալներ: Դրանք ուղարկվում են ձեզ ձեր այցելած կայքերից և պահվում են ձեր սարքի ներքին հիշողության մեջ:

                            Այս ծառայությունը բացահայտորեն չի օգտագործում այս թխուկները: Այնուամենայնիվ, հավելվածը կարող է օգտագործել երրորդ կողմի կոդը և գրադարաններ, որոնք օգտագործում են թխուկներ՝ տեղեկատվություն հավաքելու և իրենց ծառայությունները բարելավելու համար: Դուք կարող եք կամ ընդունել կամ մերժել այս թխուկները և իմանալ, թե երբ է cookie ուղարկվում ձեր սարքին: Եթե որոշեք մերժել մեր թխուկները, հնարավոր է, որ չկարողանաք օգտվել այս Ծառայության որոշ մասերից: Մենք կարող ենք աշխատանքի ընդունել երրորդ կողմի ընկերություններին և անհատներին հետևյալ պատճառներով.

                            Մեր Ծառայությունը հեշտացնելու համար;



                            Ծառայությունը մեր անունից տրամադրելու համար.



                            Ծառայությունների հետ կապված ծառայություններ իրականացնելու համար կամ



                            Օգնելու մեզ վերլուծել, թե ինչպես է օգտագործվում մեր Ծառայությունը:



                            Մենք ցանկանում ենք տեղեկացնել այս Ծառայության օգտատերերին, որ այս երրորդ կողմերin հասանելի են ձեր Անձնական տեղեկատվությանը:

                            Պատճառը մեր անունից իրենց հանձնարարված խնդիրները կատարելն է։ Այնուամենայնիվ, նրանք պարտավոր են չհրապարակել և չօգտագործել տեղեկատվությունը որևէ այլ նպատակով:

                            Մենք գնահատում ենք ձեր վստահությունը մեզ ձեր անձնական տեղեկությունները տրամադրելու հարցում, հետևաբար մենք ձգտում ենք օգտագործել դրանք պաշտպանելու առևտրային առումով ընդունելի միջոցներ: Բայց հիշեք, որ ինտերնետի միջոցով փոխանցման ոչ մի մեթոդ կամ էլեկտրոնային պահպանման եղանակ 100% անվտանգ և հուսալի չէ, և մենք չենք կարող երաշխավորել դրա բացարձակ անվտանգությունը:

                            Հղումներ դեպի այլ կայքեր Այս ծառայությունը կարող է պարունակել այլ կայքերի հղումներ: Եթե սեղմեք երրորդ կողմի հղմանը, դուք կուղղորդվեք այդ կայք: Նկատի ունեցեք, որ այս արտաքին կայքերը չեն շահագործվում մեր կողմից: Հետևաբար, մենք խստորեն խորհուրդ ենք տալիս վերանայել այս կայքերի Գաղտնիության քաղաքականությունը:

                            Մենք վերահսկողություն չունենք և պատասխանատվություն չենք կրում երրորդ կողմի կայքերի կամ ծառայությունների բովանդակության, գաղտնիության քաղաքականության կամ գործելակերպի համար:

                            Փոփոխություններ այս գաղտնիության քաղաքականության մեջ Մենք կարող ենք ժամանակ առ ժամանակ թարմացնել մեր Գաղտնիության քաղաքականությունը: Այսպիսով, ձեզ խորհուրդ է տրվում պարբերաբար վերանայել այս էջը ցանկացած փոփոխության համար:

                            Մենք ձեզ կտեղեկացնենք ցանկացած փոփոխության մասին՝ այս էջում տեղադրելով Գաղտնիության նոր քաղաքականությունը:

                            Այս փոփոխություններն ուժի մեջ են մտնում այս էջում տեղադրվելուց անմիջապես հետո: Կապվեք մեզ հետ Եթե ունեք հարցեր կամ առաջարկություններ մեր Գաղտնիության քաղաքականության վերաբերյալ support@inorain.com հասցեով:



                            1. Նախաբան



                            Առցանց վաճառքի հետևյալ պայմանները կարգավորում են I Player կայքի միջոցով հաստատված բոլոր գործարքները: Այս վեբ կայքի միջոցով իրականացվող ցանկացած գործարք ենթադրում է հաճախորդի կողմից այս պայմանների անվերապահ և անվերադարձ ընդունումը:



                            2. Նպատակը



                            Այս պայմանները նպատակ ունեն սահմանելու կողմերի իրավունքներն ու պարտականությունները I Player կայքի միջոցով իրականացվող առցանց վաճառքի համատեքստում:



                            3. Վաճառք



                            Հաճախորդը միանում է I Player կայքին և ընտրում բաժանորդագրություններ, որոնք նա ցանկանում է գնել: Այնուհետև նա լրացնում է իր ինքնության տվյալները, ընդունում է պայմանները և սեղմում «Վճարել» կոճակը: Այսպիսով, հաճախորդը տեղափոխվում է վճարային համակարգի էջ ,որտեղ նա լրացնում է իր վճարման տեղեկատվությունը և վավերացնում վճարումը: Մեր բոլոր վաճառքները վերջնական են, և ոչ մի փոխհատուցում չի թույլատրվում նույնիսկ հավելվածի դադարեցումից և օգտատիրոջ հաշվի ակտիվացումից հետո: I Player կայքը իրավունք է վերապահում դադարեցնել ցանկացած օգտվողի ակտիվացումը ցանկացած ժամանակ և առանց դադարեցման որևէ պատճառ ներկայացնելու:



                            4. Վճարման եղանակներ



                            Ինտերնետում քարտով վճարելու համար հաճախորդը կոչված է մուտքագրելու իր վճարային քարտի տվյալները անվտանգ վճարման էջում: Երբ գործարքն ընդունվում է բանկի կողմից, նրա հաշվի դեբետավորման համար հաշվարկը կատարվում է գործարքի հաստատման օրվան հաջորդող աշխատանքային օրը: Քարտերով առցանց վճարումը ապահովված է տվյլալ վճարային համակարգի կողմից, որն ապահովում է լիովին անվտանգ վճարային ծառայություն:

                            Հաճախորդը երաշխավորում է I Player կայքը և I Player ընկերությանը, որ ունի բավարար միջոցներ, որոնք անհրաժեշտ են վճարման վավերացման ընթացքում իր կողմից ընտրված վճարման եղանակն օգտագործելու համար: Քարտով վճարելիս կիրառվում են վճարման այս եղանակի պայմանները, որոնք պարունակվում են հաճախորդի և նրա թողարկող բանկի և I Player կայքի և նրա բանկի միջև կնքված պայմանագրերում:



                            5. Տվյալների գաղտնիություն



                            Առցանց վճարման ընթացքում հաճախորդից պահանջվող տեղեկատվությունը պահանջվում է առցանց գործարքը մշակելու համար: Նրանց հետ վարվում են գաղտնի: Հաճախորդն իրավունք ունի խորհրդակցելու կամ շտկելու իր անձնական տվյալները՝ հարցում ուղարկելով էլեկտրոնային նամակ հետևյալ էլ.փոստի support@inorain.com հասցեին:



                            6. Վճարման ապացույց



                            Վճարային համակարգից տրվող վճարման անդորագիրը համարվում է առցանց գործարքի վճարման ապացույց:





                            I Player հավելվածը առանց որևէ բովանդակության է: Բովանդակություն չի տրամադրվում նույնիսկ մեր կայքում՝ այն ակտիվացնելուց հետո:

                            Սկզբում ցուցադրվում է ՄԱԿ հասցեի և վեբ կայքի ինֆորմացիա, որպեսզի հաճախորդները կարողանան ակտիվացնել հավելվածը ցմահ մեկ անգամ:

                            I Player Մեդիա Փլեյեր հավելվածն ունի 5-օրյա անվճար փորձաշրջան՝ հավելվածի հնարավորությունները ստուգելու համար:

                            MAC հասցեն և URL-ը կարևոր են հավելվածի պատշաճ գործունեության համար:

                            I Player-ը չի տրամադրում որևէ կոնտենտ կամ բաժանորդագրություն:

                            I Player-ի անվան տակ տրամադրված բովանդակությունը և բաժանորդագրությունները խարդախություններ են և պատասխանատվություն չի կրում այլ կոնտենտով օգտագործման համար:

                            I Player հավելվածը չի կարող պատասխանատվություն կրել I Player-ում օգտագործվող բովանդակության որակի կամ ընդհատումների համար։

                            I Player-ը տրամադրում է միայն մեդիա փլեյեր:

                            I Player հավելվածը գովազդ չի տրմամադրում կոնտենտ պրովայդերներին կամ բաժանորդագրություն առաջարկողներին և իրեն իրավունք է վերապահում դատի տալ բոլորին, ովքեր օգտագործում են մեր անունը կամ պատկերանշանը գովազդային նպատակներով:

                            Վերոնշյալ պայմաններն ընդունելուց հետո ոչ մի դեպքում գումարի վերադարձ չի տրվի:

                            Նշում. Եթե դուք օգտագործում եք գողացված քարտ, ձեր ՄԱԿ-ը կարգելափակվի, և դրա IP հասցեն ևս:

                            I Player-ը պատկանում և շահագործվում է I Player-ի կողմից, որն իրեն իրավունք է վերապահում սահմանափակել մուտքը դեպի հավելված, փոխել դրա գինը և փոփոխել օգտագործման պայմանները ցանկացած ժամանակ՝ առանց իր վերջնական օգտատիրոջ համաձայնության:



                            Օգտագործողի պարտականությունն է հետևել թարմացումներին։



                            Փոխհատուցման քաղաքականություն



                            Հուսով ենք, որ դուք գոհ եք ձեր գնումից:

                            Այնուամենայնիվ, եթե որևէ պատճառով լիովին գոհ չեք ձեր գնումից, կարող եք բացել գումարի վերադարձի հարցում, և մենք ամբողջությամբ կվերադարձնենք գումարը:

                            Ձեր հարցումը ստանալուց հետո մենք այն կմշակենք 3 աշխատանքային օրվա ընթացքում:

                            Ձեր հաշվին գումար վերադարձնելու համար կարող է պահանջվել մինչև 90 աշխատանքային օր՝ կախված ձեր քարտի ընկերության կամ բանկից:

                            Գումարի վերադարձի համար մեզ անհրաժեշտ կլինեն ձեր գնման վերաբերյալ մանրամասներ, ինչպիսիք են Գործարքի ID-ն, կամ անդորագիրը կամ էլ. փոստը:



                            Առաքման քաղաքականություն



                            Մեր արտադրանքը թվային ապրանք է, և վճարումից հետո դուք մուտք կունենաք ապրանքին և իր բոլոր հնարավորություններին:

                            Երբեմն վճարումը կարող է հաստատվել մեկ ժամվա ընթացքում, և եթե հավելվածն ակտիվացված չէ, խնդրում ենք դուրս գալ և նորից մուտք գործել:



                            Եթե հավելվածն այնուհանդերձ ակտիվացված չէ, խնդրում ենք լրացնել «Կապ մեզ հետ» ձևաթուղթը` տրամադրելով անհրաժեշտ տեղեկությունները:I Player-ը ստեղծել է Մեդիա փլեյեր հավելվածը, որպես կոմերցիոն հավելված:

                            Այս ԾԱՌԱՅՈՒԹՅՈՒՆԸ տրամադրվում է inoRain LLC-ի, Inorain - Georgia LLC-ի կողմից և նախատեսված է օգտագործելու համար, ինչպես կա:

                            Այս էջն օգտագործվում է այցելուներին տեղեկացնելու մեր քաղաքականության վերաբերյալ անձնական տեղեկատվության հավաքագրման, օգտագործման և բացահայտման վերաբերյալ, եթե որևէ մեկը որոշել է օգտվել մեր Ծառայությունից:

                            Եթե դուք ընտրում եք օգտվել մեր Ծառայությունից, ապա դուք համաձայնում եք տեղեկատվության հավաքագրմանը և օգտագործմանը այս քաղաքականությանը:

                            Անձնական տեղեկատվությունը, որը մենք հավաքում ենք, օգտագործվում է Ծառայությունը տրամադրելու և բարելավելու համար: Մենք չենք օգտագործի կամ կիսվի ձեր տեղեկությունները որևէ մեկի հետ, բացառությամբ սույն Գաղտնիության քաղաքականության մեջ նկարագրվածի: Սույն Գաղտնիության քաղաքականության մեջ օգտագործվող տերմիններն ունեն նույն նշանակությունը, ինչ մեր Պայմաններում և դրույթներում, որը հասանելի է <Link to="/terms-of-use" >https://i-player.live/#/terms-of-use</Link> կայքում, եթե այլ բան սահմանված չէ սույն Գաղտնիության քաղաքականության մեջ:

                            Տեղեկությունների հավաքագրում և օգտագործում Մեր Ծառայությունից օգտվելիս ավելի լավ փորձի համար մենք կարող ենք պահանջել, որ մեզ տրամադրեք որոշակի անձը հաստատող տեղեկություններ, ներառյալ, MAC հասցեն, էլ. փոստը, անուն, ազգանուն, հասցե, հեռախոսահամար:

                            Տեղեկությունները, որոնք մենք պահանջում ենք, կպահվեն մեր կողմից և կօգտագործվեն այնպես, ինչպես նկարագրված է այս գաղտնիության քաղաքականության մեջ:

                            Հավելվածն օգտագործվում է երրորդ կողմի ծառայությունների կողմից, որոնք կարող են հավաքել տեղեկատվություն, որն օգտագործվում է ձեզ նույնականացնելու համար: Մենք հավաքում ենք տեղեկությունները Log Data-ում, որպեսզի հավելվածում սխալի դեպքում կարողանանք ուղղել դրանք: Աըդ տեղեկությունները կարող են ներառել այնպիսի տեղեկություններ, ինչպիսիք են՝ ձեր սարքի ինտերնետային արձանագրության IP հասցեն, MAC հասցեն, սարքի անունը, օպերացիոն համակարգի տարբերակը,device key-ն, մեր Ծառայությունից օգտվելիս, Ծառայությունից օգտվելու ժամը և ամսաթիվը և այլ վիճակագրություն: Cookie-ները փոքր քանակությամբ տվյալներ ունեցող ֆայլեր են, որոնք սովորաբար օգտագործվում են որպես անանուն եզակի նույնականացնող տվյալներ: Դրանք ուղարկվում են ձեզ ձեր այցելած կայքերից և պահվում են ձեր սարքի ներքին հիշողության մեջ:

                            Այս ծառայությունը բացահայտորեն չի օգտագործում այս թխուկները: Այնուամենայնիվ, հավելվածը կարող է օգտագործել երրորդ կողմի կոդը և գրադարաններ, որոնք օգտագործում են թխուկներ՝ տեղեկատվություն հավաքելու և իրենց ծառայությունները բարելավելու համար: Դուք կարող եք կամ ընդունել կամ մերժել այս թխուկները և իմանալ, թե երբ է cookie ուղարկվում ձեր սարքին: Եթե որոշեք մերժել մեր թխուկները, հնարավոր է, որ չկարողանաք օգտվել այս Ծառայության որոշ մասերից: Մենք կարող ենք աշխատանքի ընդունել երրորդ կողմի ընկերություններին և անհատներին հետևյալ պատճառներով.

                            Մեր Ծառայությունը հեշտացնելու համար;



                            Ծառայությունը մեր անունից տրամադրելու համար.



                            Ծառայությունների հետ կապված ծառայություններ իրականացնելու համար կամ



                            Օգնելու մեզ վերլուծել, թե ինչպես է օգտագործվում մեր Ծառայությունը:



                            Մենք ցանկանում ենք տեղեկացնել այս Ծառայության օգտատերերին, որ այս երրորդ կողմերin հասանելի են ձեր Անձնական տեղեկատվությանը:

                            Պատճառը մեր անունից իրենց հանձնարարված խնդիրները կատարելն է։ Այնուամենայնիվ, նրանք պարտավոր են չհրապարակել և չօգտագործել տեղեկատվությունը որևէ այլ նպատակով:

                            Մենք գնահատում ենք ձեր վստահությունը մեզ ձեր անձնական տեղեկությունները տրամադրելու հարցում, հետևաբար մենք ձգտում ենք օգտագործել դրանք պաշտպանելու առևտրային առումով ընդունելի միջոցներ: Բայց հիշեք, որ ինտերնետի միջոցով փոխանցման ոչ մի մեթոդ կամ էլեկտրոնային պահպանման եղանակ 100% անվտանգ և հուսալի չէ, և մենք չենք կարող երաշխավորել դրա բացարձակ անվտանգությունը:

                            Հղումներ դեպի այլ կայքեր Այս ծառայությունը կարող է պարունակել այլ կայքերի հղումներ: Եթե սեղմեք երրորդ կողմի հղմանը, դուք կուղղորդվեք այդ կայք: Նկատի ունեցեք, որ այս արտաքին կայքերը չեն շահագործվում մեր կողմից: Հետևաբար, մենք խստորեն խորհուրդ ենք տալիս վերանայել այս կայքերի Գաղտնիության քաղաքականությունը:

                            Մենք վերահսկողություն չունենք և պատասխանատվություն չենք կրում երրորդ կողմի կայքերի կամ ծառայությունների բովանդակության, գաղտնիության քաղաքականության կամ գործելակերպի համար:

                            Փոփոխություններ այս գաղտնիության քաղաքականության մեջ Մենք կարող ենք ժամանակ առ ժամանակ թարմացնել մեր Գաղտնիության քաղաքականությունը: Այսպիսով, ձեզ խորհուրդ է տրվում պարբերաբար վերանայել այս էջը ցանկացած փոփոխության համար:

                            Մենք ձեզ կտեղեկացնենք ցանկացած փոփոխության մասին՝ այս էջում տեղադրելով Գաղտնիության նոր քաղաքականությունը:

                            Այս փոփոխություններն ուժի մեջ են մտնում այս էջում տեղադրվելուց անմիջապես հետո: Կապվեք մեզ հետ Եթե ունեք հարցեր կամ առաջարկություններ մեր Գաղտնիության քաղաքականության վերաբերյալ support@inorain.com հասցեով:



                            1. Նախաբան



                            Առցանց վաճառքի հետևյալ պայմանները կարգավորում են I Player կայքի միջոցով հաստատված բոլոր գործարքները: Այս վեբ կայքի միջոցով իրականացվող ցանկացած գործարք ենթադրում է հաճախորդի կողմից այս պայմանների անվերապահ և անվերադարձ ընդունումը:



                            2. Նպատակը



                            Այս պայմանները նպատակ ունեն սահմանելու կողմերի իրավունքներն ու պարտականությունները I Player կայքի միջոցով իրականացվող առցանց վաճառքի համատեքստում:



                            3. Վաճառք



                            Հաճախորդը միանում է I Player կայքին և ընտրում բաժանորդագրություններ, որոնք նա ցանկանում է գնել: Այնուհետև նա լրացնում է իր ինքնության տվյալները, ընդունում է պայմանները և սեղմում «Վճարել» կոճակը: Այսպիսով, հաճախորդը տեղափոխվում է վճարային համակարգի էջ ,որտեղ նա լրացնում է իր վճարման տեղեկատվությունը և վավերացնում վճարումը: Մեր բոլոր վաճառքները վերջնական են, և ոչ մի փոխհատուցում չի թույլատրվում նույնիսկ հավելվածի դադարեցումից և օգտատիրոջ հաշվի ակտիվացումից հետո: I Player կայքը իրավունք է վերապահում դադարեցնել ցանկացած օգտվողի ակտիվացումը ցանկացած ժամանակ և առանց դադարեցման որևէ պատճառ ներկայացնելու:



                            4. Վճարման եղանակներ



                            Ինտերնետում քարտով վճարելու համար հաճախորդը կոչված է մուտքագրելու իր վճարային քարտի տվյալները անվտանգ վճարման էջում: Երբ գործարքն ընդունվում է բանկի կողմից, նրա հաշվի դեբետավորման համար հաշվարկը կատարվում է գործարքի հաստատման օրվան հաջորդող աշխատանքային օրը: Քարտերով առցանց վճարումը ապահովված է տվյլալ վճարային համակարգի կողմից, որն ապահովում է լիովին անվտանգ վճարային ծառայություն:

                            Հաճախորդը երաշխավորում է I Player կայքը և I Player ընկերությանը, որ ունի բավարար միջոցներ, որոնք անհրաժեշտ են վճարման վավերացման ընթացքում իր կողմից ընտրված վճարման եղանակն օգտագործելու համար: Քարտով վճարելիս կիրառվում են վճարման այս եղանակի պայմանները, որոնք պարունակվում են հաճախորդի և նրա թողարկող բանկի և I Player կայքի և նրա բանկի միջև կնքված պայմանագրերում:



                            5. Տվյալների գաղտնիություն



                            Առցանց վճարման ընթացքում հաճախորդից պահանջվող տեղեկատվությունը պահանջվում է առցանց գործարքը մշակելու համար: Նրանց հետ վարվում են գաղտնի: Հաճախորդն իրավունք ունի խորհրդակցելու կամ շտկելու իր անձնական տվյալները՝ հարցում ուղարկելով էլեկտրոնային նամակ հետևյալ էլ.փոստի support@inorain.com հասցեին:



                            6. Վճարման ապացույց



                            Վճարային համակարգից տրվող վճարման անդորագիրը համարվում է առցանց գործարքի վճարման ապացույց:





                            I Player հավելվածը առանց որևէ բովանդակության է: Բովանդակություն չի տրամադրվում նույնիսկ մեր կայքում՝ այն ակտիվացնելուց հետո:

                            Սկզբում ցուցադրվում է ՄԱԿ հասցեի և վեբ կայքի ինֆորմացիա, որպեսզի հաճախորդները կարողանան ակտիվացնել հավելվածը ցմահ մեկ անգամ:

                            I Player Մեդիա Փլեյեր հավելվածն ունի 5-օրյա անվճար փորձաշրջան՝ հավելվածի հնարավորությունները ստուգելու համար:

                            MAC հասցեն և URL-ը կարևոր են հավելվածի պատշաճ գործունեության համար:

                            I Player-ը չի տրամադրում որևէ կոնտենտ կամ բաժանորդագրություն:

                            I Player-ի անվան տակ տրամադրված բովանդակությունը և բաժանորդագրությունները խարդախություններ են և պատասխանատվություն չի կրում այլ կոնտենտով օգտագործման համար:

                            I Player հավելվածը չի կարող պատասխանատվություն կրել I Player-ում օգտագործվող բովանդակության որակի կամ ընդհատումների համար։

                            I Player-ը տրամադրում է միայն մեդիա փլեյեր:

                            I Player հավելվածը գովազդ չի տրմամադրում կոնտենտ պրովայդերներին կամ բաժանորդագրություն առաջարկողներին և իրեն իրավունք է վերապահում դատի տալ բոլորին, ովքեր օգտագործում են մեր անունը կամ պատկերանշանը գովազդային նպատակներով:

                            Վերոնշյալ պայմաններն ընդունելուց հետո ոչ մի դեպքում գումարի վերադարձ չի տրվի:

                            Նշում. Եթե դուք օգտագործում եք գողացված քարտ, ձեր ՄԱԿ-ը կարգելափակվի, և դրա IP հասցեն ևս:

                            I Player-ը պատկանում և շահագործվում է I Player-ի կողմից, որն իրեն իրավունք է վերապահում սահմանափակել մուտքը դեպի հավելված, փոխել դրա գինը և փոփոխել օգտագործման պայմանները ցանկացած ժամանակ՝ առանց իր վերջնական օգտատիրոջ համաձայնության:



                            Օգտագործողի պարտականությունն է հետևել թարմացումներին։



                            Փոխհատուցման քաղաքականություն



                            Հուսով ենք, որ դուք գոհ եք ձեր գնումից:

                            Այնուամենայնիվ, եթե որևէ պատճառով լիովին գոհ չեք ձեր գնումից, կարող եք բացել գումարի վերադարձի հարցում, և մենք ամբողջությամբ կվերադարձնենք գումարը:

                            Ձեր հարցումը ստանալուց հետո մենք այն կմշակենք 3 աշխատանքային օրվա ընթացքում:

                            Ձեր հաշվին գումար վերադարձնելու համար կարող է պահանջվել մինչև 90 աշխատանքային օր՝ կախված ձեր քարտի ընկերության կամ բանկից:

                            Գումարի վերադարձի համար մեզ անհրաժեշտ կլինեն ձեր գնման վերաբերյալ մանրամասներ, ինչպիսիք են Գործարքի ID-ն, կամ անդորագիրը կամ էլ. փոստը:



                            Առաքման քաղաքականություն



                            Մեր արտադրանքը թվային ապրանք է, և վճարումից հետո դուք մուտք կունենաք ապրանքին և իր բոլոր հնարավորություններին:

                            Երբեմն վճարումը կարող է հաստատվել մեկ ժամվա ընթացքում, և եթե հավելվածն ակտիվացված չէ, խնդրում ենք դուրս գալ և նորից մուտք գործել:

                            Եթե հավելվածն այնուհանդերձ ակտիվացված չէ, խնդրում ենք լրացնել «Կապ մեզ հետ» ձևաթուղթը` տրամադրելով անհրաժեշտ տեղեկությունները:</div>
                    </> :
                        <>
                            <h1>{handleText("page_name")}</h1>
                            <div dangerouslySetInnerHTML={{ __html: handleText("content") }} />
                        </>
                }

            </div>
        </PageComponent>
    );
}
